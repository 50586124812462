import DefaultOptions from './AppOptions'
import CdekOptions from './cdek/AppOptions'
import RwOptions from './rw/AppOptions'
import OAWms from './oawms/AppOptions'
import AppDevOptions from './app-dev/AppOptions'
import Callplex from './callplex/AppOptions'
import Cargoplanet from './cargoplanet/AppOptions'
import FastPrep from './fastprepusa/AppOptions'
import ShippedBG from './shippedbg/AppOptions'
import SmartPrepCenterOptions from './smartprepcenter/AppOptions'
import PrepadminOptions from './prepadmin/AppOptions'
import DemoOptions from './demo/AppOptions'
import ZtMainOptions from './ztmain/AppOptions'
import Lifjot from './lifjot/AppOptions'
import Mysend from './mysend/AppOptions'
import Salesinusa from './salesinusa/AppOptions'
import Luckyprep from './luckyprep/AppOptions'
import Longroad from './longroad/AppOptions'
import Izprati from './izprati/AppOptions'

export default [
  DefaultOptions,
  AppDevOptions,
  CdekOptions,
  RwOptions,
  OAWms,
  Callplex,
  Cargoplanet,
  FastPrep,
  ShippedBG,
  SmartPrepCenterOptions,
  PrepadminOptions,
  DemoOptions,
  ZtMainOptions,
  Lifjot,
  Mysend,
  Salesinusa,
  Luckyprep,
  Longroad,
  Izprati,
]
