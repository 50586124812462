export default {
  id: 'izprati',
  versionType: 'simple', // default or simple
  debug: false,
  app: 'orderadmin',
  title: 'Изпрати.БГ',
  domain: 'izpratibg',
  localServer: 'app.izprati.bg',
  defaultServer: 'https://pro.oawms.com',
  securityLogo: 'assets/logos/izprati/logo-bg-square.png',
  logoSrc: 'assets/logos/izprati/logo-bg-square.png',
  logoLeft: 'assets/logos/izprati/logo-bg-square.png',
  logoTop: 'assets/logos/izprati/logo-line-bg.png',
  theme: 'default',
  formats: {
    internationalDate: 'LLL',
    date: 'DD/MM/YYYY',
    fullDate: 'DD/MM/YYYY hh:mm A z',
    defaultTimezone: 'Europe/Sofia'
  },
  servers: [],
  locale: {
    name: 'Български',
    locale: 'bg',
    flag: 'bg'
  },
  automaticCreatedEntities: {
    mysend: {
      sender: {},
      shop: {}
    }
  },
  country: {
    id: 25
  },
  defaults: {
    addressFormat: 'domestic',
    order: {
      paymentState: 'paid'
    }
  },
  tables: {
    paginationOptions: {
      enabled: true,
      mode: 'pages',
      perPage: 25
    }
  },
  packagingSettings: {
    acceptanceControl: false,
    consumptionControl: false,
    weightControl: true,
    maxWeight: 14000,
    consumption: null,
    checkParcelContent: false,
    trustIncomingMeasurements: true,
    setPredefinedMeasurements: false,
    isEnabledDeliveryRequestTable: false,
    default: {
      weight: 1,
      width: 10,
      length: 10,
      height: 10,
      sendDate: null
    },
    printing: {
      interceptAutoClose: false,
      autoClosePrintWindow: true,
      modal: false,
      timeout: 4500
    }
  },
  defaultDimensions: {
    weight: 1,
    dimensions: {
      x: 10,
      y: 10,
      z: 10
    },
    weightUnit: 'g',
    dimensionUnit: 'mm'
  },
  orderTypes: {
    retail: {
      type: 'retail',
      label: 'Simple',
      options: {
        type: 'retail'
      }
    }
  },
  senderWarning: false,
  termsLink: 'http://izprati.bg/policies/terms/',
  cookiesLink: 'http://izprati.bg/policies/cookies/'
}
